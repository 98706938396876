@import "../../../../styles/variables/colors";


.verdicts-table {
  border-spacing: 0px;
  width: 100%;

  &--head {
    background-color: $primary-dark;

    &--cell {
      text-align: left;
      padding-left: 10px;
      padding-top: 11px;
      padding-bottom: 11px;
      border-right: 1px dashed $white;

      &:last-child {
        border-right: none;
      }
    }
  }

  &--body {
    background-color: $primary-white;

    &--row {
      cursor: pointer;


      &--cell {
        border-bottom: 1px dashed $primary-grey;
        padding: 10px;
        border-left: 1px dashed $primary-grey;

        &:last-child {
          border-right: 1px dashed $primary-grey;
        }

        &--actions {
          display: flex;
          gap: 10px;
        }
      }
    }

    &--row:hover {
      background-color: $tertiary-blue
    }

  }
}
