@import "../../../../styles/variables/colors";


@keyframes slideInFromBottom {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}



.footerBar {
  align-items: center;
  animation: slideInFromBottom 0.5s;
  background-color: $primary-dark;
  color: white;
  display: flex;
  align-items: center;
  height: 94px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 999;

  &--content{
    @media only screen and (max-width: 767px) {
      padding: 0px 16px 0px 16px;
    }
    padding: 0px 96px 0px 96px;
    width: 100%;
  }
}
