@import "../../../styles/variables/colors";

.dashboard {
  display: flex;
  width: 100%;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  border-right: 1px dashed $primary-grey;
  border-left: 1px dashed $primary-grey;


  &--divider {
    margin: 0 30px;
  }

  &--metrics {
    width: 55%;
    display: flex;
    flex-direction: column;

    &--header {
      display: flex;
      justify-content: space-between;

      &--link {
        display: flex;
        align-items: center;
        svg {
          margin-left: 14px;
        }
      }

      &--sources {
        margin-top: 30px;

        &--row {
          display: flex;
          gap: 30px;

          &--item {
            width: 50%;
          }
        }
      }
    }

    &--source-container {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &--statistics-container {
      align-items: flex-start;
      padding-top: 30px;
      display: flex;

      &--statistic-card {
        border: 1px dashed $primary-grey;
        width: 100%;
      }
    }

    &--verdicts-metrics-container {
      align-items: flex-start;
      padding-top: 30px;
      display: flex;

      &--card {
        border: 1px dashed $primary-grey;
        display: flex;
        flex-direction: column;
        padding: 20px 28px;
        text-decoration: none;
        width: 100%;

        &--clickable {
          &:hover {
            background-color: $tertiary-grey;
            cursor: pointer;
          }
        }
        &--footer {
          display: flex;
          flex-direction: row;
          gap: 10px;
          padding: 8px 0px;
          justify-content: space-between;

          &--item {
            align-items: center;
            display: flex;
            margin-left: 40px;

            &--before-divider   {
              display: flex;
            }
          }
        }

      }
    }
  }
}
