@import "../../../styles/variables/colors";

.searchPage {
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;

  &--filters {
    display: none;
  }

  &--title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
  }

  &--panel {
    align-items: center;
    border-top: 1px dashed $primary-grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;

    &--tabs {
      display: flex;
      text-align: center;
      width: 100%;
      padding: 0 8px;
      border-bottom: 1px dashed $primary-grey;

      &--tab {
        width: 50%;
        padding: 20px;

        &-isActive {
          color: $primary-dark !important;
          border-bottom: 3px solid $primary-dark;

          border-bottom: 4px solid $primary-dark;
          & > p {
            color: $primary-dark !important;
          }
        }
      }
    }

    &--filter {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      width: 100%;

      &--dropdown-container {
        max-width: 200px;
      }
    }
  }

  &--review-list {
    margin-bottom: 60px;
    padding-top: 30px;

    &--item {
      margin-bottom: 30px;
      border: 1px dashed $primary-grey;
    }
  }

  &--source-list {
    margin-bottom: 60px;
    padding-top: 30px;

    &--item {
      margin-bottom: 30px;
      border: 1px dashed $primary-grey;
    }
  }

}

@media (min-width: 810px) {

  .searchPage {
    padding: 0 40px;

    &--title {
      margin: 60px 0;
    }

    &--panel {
      flex-direction: row;
      border-bottom: 1px dashed $primary-grey;

      &--tabs {
        border-bottom: none;
      }

      &--filter {
        justify-content: flex-end;
        padding-top: 0;
        margin-right: 20px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .searchPage {
    margin: 0 80px;
    padding: 0;

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      background-color: $primary-white;
      z-index: 2;
      width: 100%;
      padding: 0 80px;
      padding-top: 20px;
    }

    &--fixed-container {
      margin-top: 70px;
      position: relative;
    }

    &--search {
      padding: 0 20px;
    }

    &--panel {
      border-top: none;

      &--tabs{
        &--tab {
          max-width: 200px;
        }
      }
      &--filter {
        display: none;
      }
    }


    &--filter-container {
      display: flex;
      min-height: 500px;

      &--tab {
        width: calc(100% - 360px);
      }
      &--filters {
        width: 360px;
        padding-top: 30px;
        padding-left: 30px;
        border-left: 1px dashed $primary-grey;

        &--fixed {
          position: fixed;
          right: 80px;
          top: 153px;
          height: 100%;
        }

        &--sort {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
        }

        p > {
          margin-bottom: 10px;
        }
      }
    }
    &--review-list {

      &--item {
        border-right: none;
        border-left: none;
      }
    }

    &--source-list {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      > :nth-child(even) {
        border-right: none;
      }

      > :nth-child(odd) {
        border-left: none;
      }

      &--item {
        width: calc(50% - 15px);
      }
    }

  }
}
