@import "../../../../styles/variables/colors";

.circleColored {
  height: 80px;
  position: relative;
  width: 80px;
  min-height: 80px;
  min-width: 80px;

  &-big {
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;
  }

  &--bg {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
    position: absolute;
    z-index: -1;

    &-big {
      height: 120px;
      width: 120px;
      min-height: 120px;
      min-width: 120px;
    }
  }

  &--label {
    align-items: center;
    color: $primary-dark;
    display: flex;
flex-direction: column;
    justify-content: center;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);

    &--value {
      font-family: Space Mono, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      text-align: center;

      &-big {
        font-size: 16px;
      }
    }

    &--unit {
      font-family: Space Mono, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      text-align: center;
      text-wrap: none;

      &-big {
        font-size: 14px;
      }
    }
  }
}
