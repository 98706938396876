@import "../../../../styles/variables/colors";


.appearance-claims {
  &--divider {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  &--readmore {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
  }
}
