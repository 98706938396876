@import "../../../../styles/variables/colors";

.add-member {
  padding: 40px 0;

  &--title {
    margin-bottom: 20px;
  }

  &--label {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
