@import "../../../../styles/variables/colors";

.feedback-request {
  &--publicQuestion {
    margin-bottom: 20px;
  }

  &--metrics {
    display: flex;
    justify-content: space-between;
  }

  &--claim {
    display: flex;
    margin-bottom: 24px;

    &--divider {
      margin-right: 22px;
    }
  }

  &--actions {
    display: flex;
    justify-content: flex-end;
  }
}

