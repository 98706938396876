@import "../../../../styles/variables/colors";

.component-button-icon {
  background-color: transparent;
  border: 1px solid $primary-dark;
  color: $primary-dark;
  font-family: Space Mono;
  align-items: center;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 18px;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
  height: 20px;

  svg {
    fill: red !important;
    stroke: red !important;

  }

  &:hover {
    background-color: $primary-blue;
    color: $primary-white;
    border: 1px solid $primary-white;

    * {
      color: $primary-white !important;
      color: $primary-white !important;
    }
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid $primary-grey;
    color: $primary-grey;

    &:hover {
      background-color: transparent;
      border: 1px solid $primary-grey;
      color: $primary-grey;

      * {
        color: $primary-grey !important;
      }
    }
  }

  &[data-variant="up"] {
    height: 45px;
    padding: 0 6px;
  }

  &[data-variant="square"] {
    height: 45px;
    width: 45px;
    border-radius: 0;
    border: none;
  }
}
