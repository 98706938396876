@import "../../../../styles/variables/colors";

.typography {
  color: $primary-dark;
  margin: 0;

  &[data-color='primary-dark']{
    color: $primary-dark;
  }

  &[data-color='primary-white']{
    color: $primary-white;
  }

  &[data-color='secondary-grey']{
    color: $secondary-grey;
  }

  &[data-color='primary-red']{
    color: $primary-red;
  }

  &[data-color='primary-blue']{
    color: $primary-blue;
  }

  &-h1 {
    font-family: Darker Grotesque, serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0.2px;
    line-height: 60px;
    width: fit-content;

    @media only screen and (max-width: 767px) {
      font-family: 'Darker Grotesque';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;

      text-align: center;
      letter-spacing: -0.2px;
    }
  }

  &-h2 {
    font-family: Darker Grotesque, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 40px;
    padding-bottom: 10px;
    width: fit-content;
    text-decoration: none;
  }

  &-h3 {
    text-decoration: none;
    font-family: 'Darker Grotesque', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;

    letter-spacing: 0.1px;


  }

  &-title-1 {
    font-family: Darker Grotesque, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  &-title-2 {
    font-family: 'Space Mono',  serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 24px;
  }

  &-title-3 {
    font-family: 'Space Mono',  serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 22px;
  }

  &-title-4 {
    font-family: 'Space Mono',  serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 16px;
  }


  &-body-text-1 {
    font-family: 'Space Mono',  serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 24px;

  }

  &-body-text-2 {
    font-family: 'Space Mono',  serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  &-body-text-3 {
    font-family: 'Space Mono',  serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 18px;

  }

  &-body-text-4 {
    font-family: 'Space Mono',  serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 18px;
  }

  &-menu-text-1 {
    font-family: 'Space Mono',  serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 18px;
  }

  &-menu-text-2 {
    font-family: 'Space Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 18px;
    text-decoration-line: underline;
  }

  &-btn-text {
    font-family: 'Space Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 18px;
  }

  &-link-text-1 {
    font-family: 'Space Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 18px;
    text-decoration: underline;

    &:hover {
      color: $primary-blue !important;
    }
  }

  &-link-text-2 {
    font-family: 'Space Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 22px;
    text-decoration: underline;


    &:hover {
      color: $primary-blue !important;
    }
  }

  &-link-text-3 {
    font-family: 'Space Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 18px;
    text-decoration: underline;


    &:hover {
      color: $primary-blue !important;
    }
  }

  &-numbers {
    color: $primary-blue !important;
    font-family: Space Mono, serif;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 0.1px;
    line-height: 18px;
  }
}
