@import "../../../../styles/variables/colors";

.source-page-desktop {
  max-width: 1238px;
  margin: 0 auto;
  width: 100%;

  &--border-ext {
    border-right: 1px dashed $primary-grey;
    border-left: 1px dashed $primary-grey;
    margin-bottom: 60px;
    padding: 0 94px;
  }

  &--border-int {
    border-right: 1px dashed $primary-grey;
    border-left: 1px dashed $primary-grey;
  }

  &--source-info {
    padding: 30px 20px 30px 20px;
  }


  &--mbfc {
    border-top:  1px dashed $primary-grey;
    padding: 30px 0;
    margin: 0 30px;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    &--value {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--related-media {
    padding: 30px 30px 14px 30px;

    &--title {
      margin-bottom: 20px;
    }


    & > a {
      width: calc(50% - 8px);
      margin-bottom: 16px;
    }
  }
  &--related-channel {
    padding: 30px 30px 14px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > a {
      width: calc(50% - 8px);
      margin-bottom: 16px;
    }

    &--title {
      margin-bottom: 16px;
      width: 100%;
    }

  }
}
