@import "../../../../styles/variables/colors";


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    transform: translateX(200px);
  }

  to {
    transform: translateX(0);
  }
}

.sideBar {
  animation: fadeIn 0.3s;
  background-color: rgba(255,255,251,0.2);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3;

  &--content {
    @media only screen and (max-width: 767px) {
      width: 100%;
      min-width: auto;
      padding: 16px 30px;
    }
    overflow: hidden;
    animation: slidein 0.3s;
    background-color: $primary-dark;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 450px;
    position: fixed;
    width: 40%;
    min-width: 500px;
    height: 100vh;
    padding: 0 30px;

    &--close {
      position: absolute;
      top: 45px;
      right: 30px;
    }
  }

  &--header {
    height: 100px;
    padding: 30px 0;
  }
  &--body {
    overflow: auto;
    height: calc(100% - 200px);
    scrollbar-width: auto;
    scrollbar-color: #ffffff #F4F5F64D;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #F4F5F64D;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 0px;
      border: 0px solid #F4F5F64D;
    }
  }
  &--footer {
    height: 100px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
