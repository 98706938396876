@import "../../../../styles/variables/colors";

.add-feedback {
  border-bottom: 1px dashed $primary-grey;
  margin-bottom: 30px;

  input {
    margin-bottom: 16px;
  }

  &--label {
    margin-bottom: 16px;
    margin-top: 4px;
  }

  &--line {
    display: flex;
    justify-content: space-around;

    & > input:first-child {
      margin-right: 8px;
    }
    & > input:last-child {
      margin-left: 8px;
    }
  }

  &--select-score {
    width: calc(50% - 8px);
  }

  &--select-tag {
    width: calc(50% - 8px);
  }

  &--action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
