@import "../../../styles/variables/colors";

.teams {
  padding: 40px 50px;
  display: flex;
  width: 100%;

  &--section {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    &--title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &--button-back {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }
    }

    &--desc {
      margin-bottom: 20px;
    }
  }

  &--label {
    margin-bottom: 10px;
  }

  &--btn-save {
    margin: 20px 0;
    margin-left: auto;
  }


  table {
    width: 100%;
    border-spacing: 0px;
    border-right: none;
    border-left: 1px dashed $primary-grey;

    th {
      background-color: $primary-dark;
      padding: 13px;
      text-align: left;
      border-right: 1px dashed $primary-grey;
    }

    td {
      border-right: 1px dashed $primary-grey;
      padding: 10px;
      border-bottom: 1px dashed $primary-grey;

      &member-delete {
       display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    td.last,th.last
    {
      border-right: none;
    }
  }

  &--table {
    &--btn-delete {
      display: flex;
      justify-content: center;
    }
  }

  &--btn-add {
    margin-top: 20px;
    margin-left: auto;
  }
}
