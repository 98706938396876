@import "../../../../styles/variables/colors";


.claim{
  display: flex;
  flex-direction: column;
  width: 100%;

  &[data-variant='dark'] {
    background-color: $primary-dark;
  }

  &--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &--body {
    margin-bottom: 32px;

    &--claimBy {
      margin-top: 8px;
      display: flex;

      &--person {
        overflow-wrap: anywhere;
        text-decoration: underline;
        white-space: normal;
      }
    }
  }

  &--footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
}
