.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;


  @media only screen and (max-width: 767px) {
    min-width: 0;
  }

  &--content {
    padding-top: 72px;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;

  }
}
