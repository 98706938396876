@import "../../../../styles/variables/colors";

.quotation-card {
  display: flex;
  padding: 16px;

  &--image {
    flex: 0 0 48px;
    margin-right: 16px;
    width: 48px;
  }
  &--body {
    // width = 100% - (width-of-image + image-margin-right)
    width: calc(100% - 63px);

    &--section {
      display: flex;
      flex-direction: column;
      min-height: 100px;

      &--header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &--channel {
          display: flex;

          &--flaggedIcon {
            margin-right: 12.5px;
          }
        }

        &--actions {
          display: flex;

          &--icon {
            margin-top: 0px;

            > .component-button {
              padding-left: 10px;
              margin-left: 6px;
            }

            &--dropdown {
              align-items: flex-start;
              background-color: $primary-white;
              border: 1px solid $primary-dark;
              display: flex;
              flex-direction: column;
              margin-left: 16px;
              min-width: 189.5px;
              padding: 8px 0px;
              position: absolute;
              z-index: 1;

              &--item {
                display: block;
                gap: 10px;
                padding: 5px 10px;
                a {
                  color: black;
                  text-decoration: none;
                }
              }

            }
          }
        }
      }

      &--content {
        &--title {
          word-break: break-word;
        }
      }
    }

    &--footer {
      &--edit-footer {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        &--select-field {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 810px) {
  .quotation-card {
    display: flex;
    padding: 20px 28px;

    &--image {
      margin-right: 16px;
      width: 100px;
      min-width: 100px;
    }

    &--body {
      // width = 100% - (width-of-image + image-margin-right)
      width: calc(100% - 115px);
    }
  }
}


@media (min-width: 1200px) {}
