@import "../../../../styles/variables/colors";


.appearance-documents {
  padding: 0 20px;

  &--body {
    padding: 22px 0;
    &--table {
      margin-top: 15px;
      width: 100%;
      border-spacing: 0px;
      border-right: 1px dashed $primary-grey;
      border-left: 1px dashed $primary-grey;

      th {
        border-right: 1px dashed $primary-white;
        background-color: $primary-dark;
        padding: 6px;
      }

      td {
        border-right: 1px dashed $primary-grey;
        padding: 10px;
        border-bottom: 1px dashed $primary-grey;
      }

      td.last,th.last {
        border-right: none;
        cursor: pointer;
        text-align: center;
      }

      &--type-size {
        display: flex;
        gap: 15px;
      }
    }
  }
}

