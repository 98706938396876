@import "../../../../../styles/variables/colors";


.appearance-card-desktop {
  display: flex;
  padding: 20px;

  &--image {
    margin-right: 20px;
    width: 87px;
    min-width: 87px;
  }


  &--body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    &--content {
      display: flex;
      flex-direction: column;
      min-height: 100px;

      &--header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &--channel {
          display: flex;

          &--flaggedIcon {
            margin-right: 12.5px;
          }
        }

        &--actions {
          display: flex;

          &--icon {
            margin-top: 0px;

            > .component-button {
              padding-left: 10px;
              margin-left: 6px;
            }

            &--dropdown {
              align-items: flex-start;
              background-color: $primary-white;
              border: 1px solid $primary-dark;
              display: flex;
              flex-direction: column;
              margin-left: 16px;
              min-width: 189.5px;
              padding: 8px 0px;
              position: absolute;
              z-index: 1;

              &--item {
                display: block;
                gap: 10px;
                padding: 5px 10px;
                a {
                  color: black;
                  text-decoration: none;
                }
              }

            }
          }
        }
      }
    }

    &--footer {
      align-items: center;
      display: flex;
      flex: 2 1;
      justify-content: space-between;

      > :only-child {
        width: 100%;
      }

      &--group-left {
        align-items: flex-end;
        display: flex;

        > :not(:last-child) {
          margin-right: 15px;
        }

        &--shares {
          display: flex;
          align-items: center;
        }
      }

      &--group-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        > :not(:last-child) {
          margin-right: 15px;
        }
      }
    }
    &--edit-footer {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      &--select-field {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
