@import "../../../../styles/variables/colors";





@media (min-width: 1200px) {
  .welcomeAndSearch {
    background-color: $primary-dark;
    color: $primary-white;
    height: 85vh;
    padding:0 110px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    &--bg {
      position: absolute;
      right: 0;
      min-width: 50%;
      object-fit: cover;
      height: 100%;
      z-index: -1;
    }
    &--content {

      &--title {
        margin-bottom: 36px;

        &--underline-wrapper {
          display: flex;
        }

        &--underline {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            height: 7px;
            display:block;
            width: 104%;
            bottom: 8px;
            z-index: -1;
            transform: translateX(-2%);
            position: absolute;
            background: #3A5DAE;
          }
        }
      }

      &--subtitle {
        margin-bottom: 36px;
      }

      &--searchbar {
        margin-bottom: 16px;
      }
      &--learn-more {
        display: flex;

        a {
          margin-left: 6px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .welcomeAndSearch {
    padding: 120px 16px;
    height: auto;
    text-align: center;
    background-color: $primary-dark;
    position: relative;
    z-index: 1;

    &--bg {
      position: absolute;
      right: 0;
      min-width: 50%;
      object-fit: cover;
      height: 100%;
      z-index: -1;
      top: 0;
    }
    &--content {

      &--title {
        margin-bottom: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--underline-wrapper {
          display: flex;
          justify-content: center;
        }

        &--underline {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            height: 7px;
            display:block;
            width: 104%;
            bottom: 8px;
            z-index: -1;
            transform: translateX(-2%);
            position: absolute;
            background: #3A5DAE;
          }
        }
      }

      &--subtitle {
        margin-bottom: 36px;
      }

      &--searchbar {
        margin-bottom: 16px;
      }

    }
  }
}



.welcomeAndSearch {

  &--content {

    &--title {

      &--underline-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

