@import "../../../../../styles/variables/colors";


.confirm-factcheck-scrapped {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 40px;

  &--text {
    flex: none;
    order: 0;
    flex-grow: 2;

  }
  &--actions {
    &--cancel {
      cursor: pointer;
    }
    order: 1;
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-around;
  }
}
