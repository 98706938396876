@import "../../../styles/variables/colors";

.scientist-dashboard {
  display: flex;
  flex-direction: column;
  padding: 24px;

  &--section {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
