@import "../../../../styles/variables/colors";


.sync-review {
  display: flex;
  width: 100%;
  &--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--form {

    &--row {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      &--checkboxes {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 30px;

        &--checkbox {
          align-items: center;
          display: flex;
          margin-top: 20px;
          gap: 10px;
        }
      }
    }

    &--button {
      display: flex;
      margin-top: 30px;
      margin-left: auto;
      width: 100px;
    }
  }
}
