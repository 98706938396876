@import "../../../../styles/variables/colors";

.component-button {
  font-family: Space Mono;
  align-items: center;
  background-color: $primary-dark;
  border: none;
  border-radius: 28px;
  color: $primary-white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 18px;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  height: 34px;

  &:hover {
    background-color: $primary-blue;
    color: $primary-white;

    * {
      color: $primary-white !important;
    }
  }


  &:disabled {
    background-color: $tertiary-grey;
    border: none;
    color: $primary-grey;

    &:hover {
      background-color: $tertiary-grey;
      border: none;
      color: $primary-grey;
      pointer-events: none;
    }
  }

  &[data-variant="default-link"] {
    background-color: transparent;
    color: $primary-dark;
    height: unset;
    padding: 0px 0px;

    &:hover {
      background-color: transparent;
      color: $primary-dark;

      * {
        color: $primary-dark !important;
      }
    }

  }

  &[data-variant="ghost"] {
    background-color: transparent;
    border: 1px solid $primary-dark;
    color: $primary-dark;

    &:hover {
      background-color: $primary-blue;
      color: $primary-white;
      border: 1px solid $primary-white;

      * {
        color:$primary-white !important;
      }
      svg {
        color: $primary-white !important;
      }

      path {
        color: $primary-white !important;
      }
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid $primary-grey;
      color: $primary-grey;

      &:hover {
        background-color: transparent;
        border: 1px solid $primary-grey;
        color: $primary-grey;

        * {
          color: $primary-grey !important;
        }
      }
    }
  }


  &[data-variant="ghost-reverse"] {
    background-color: transparent;
    border: 1px solid $primary-white;
    color: $primary-white;

    &:hover {
      background-color: $primary-blue;
      color: $primary-white;
      border: 1px solid $primary-blue;
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid $primary-grey;
      color: $primary-grey;

      &:hover {
        background-color: transparent;
        border: 1px solid $primary-grey;
        color: $primary-grey;
      }
    }
  }

  &[data-variant="ghost-reverse-link"] {
    background-color: transparent;
    color: $primary-white;
    height: unset;
    padding: 0px 0px;

    &:hover {
      background-color: transparent;
      color: $primary-white;

      * {
        color: $primary-white !important;
      }
    }
  }

  &[data-variant="light"] {
    background-color: transparent;
    color: $primary-dark;
    transition: 0.15s all ease;
    padding: 0;

    &:disabled {
      background-color: transparent;
      color: $primary-grey;

      &:hover {
        background-color: transparent;
        color: $primary-grey;
        margin: 0;
        pointer-events: none;
      }
    }

    &:hover {
      background-color: transparent;
      color: $primary-blue;
      margin-right: 5px;

      path {
        stroke: $primary-blue;;
      }
      * {
        color:$primary-blue !important;
      }
    }

    &:disabled {
      background-color: transparent;
      color: $primary-grey;

      path {
        stroke: $primary-grey;;
      }
      * {
        color:$primary-grey !important;
      }

      &:hover {
        background-color: transparent;
        color: $primary-grey;
      }
    }
  }
}
