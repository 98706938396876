@import "../../../../styles/variables/colors";

.feedback {
  width: 100%;
  margin: 0 128px;
  border-left: 1px dashed $primary-grey;
  border-right: 1px dashed $primary-grey;

  &--border {
    margin: 0 94px;
    padding: 60px 0;

    border-left: 1px dashed $primary-grey;
    border-right: 1px dashed $primary-grey;
    height: 100%;
  }

  &--request-section {
    border-bottom: 1px dashed $primary-grey;
    padding: 0 21px;
    padding-bottom: 34px;
  }

  &--list {
    padding: 50px 21px;

    &--title {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}



