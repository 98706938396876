@import "../../../../styles/variables/colors";

.source-page-mobile {
  padding-left: 16px;
  padding-right: 16px;

  &--source-info {
    margin: 30px 0px 30px 0px;
  }

  &--source-stats {
    margin: 30px 0px 30px 0px;
  }

  &--related-medias {
    margin: 30px 0px 30px 0px;

    &--media {
      margin-top: 30px;
    }
  }
  &--related-channels {
    margin: 30px 0px 30px 0px;

    &--channel {
      margin-top: 30px;
    }
  }

  &--appearanceList {
    margin-top: 30px;

    &--appearance {
      border: 1px dashed $primary-grey;
      margin-top: 30px;
    }
  }
}
