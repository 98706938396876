@import "../../../../styles/variables/colors";

.search-bar {
  display: flex;
  width: 100%;
  position: relative;

  input {
    padding-right: 32px;
  }

  &--icon {
    position: absolute;
    right: 146px;
    top: 7px;

    @media (max-width: 767px) {
      right: 10px;
    }

    @media (max-width: 500px) {
      visibility: hidden;
    }

  }

  button {
    margin-left: 16px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
