@import "../../../../../../../styles/variables/colors";


.input-field {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-family: Space Mono, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: $tertiary-grey;
  color: $primary-dark;

  &:hover {
    background-color: $primary-dark;
    color: $tertiary-grey;

    svg {
      fill: $tertiary-grey;
    }
  }

  &--label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: Space Mono;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;

  }


  &--input {
    display: none;
  }
}
