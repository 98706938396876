@import "../../../styles/variables/colors";

.reviewpage-desktop {
  background-color: $primary-white;
  flex: 1 1 auto;


  &--dotted-list {

    &--content {
      &--header {
     display: none;
      }

      &--review-details {
        border-bottom: 1px dashed $primary-grey;
        min-height: 80px;
        padding: 16px 20px;
        display:flex;
        align-items:center; /*for vertically center*/
      }

      &--body {
        padding: 0 20px;

        &--title {
          margin-bottom: 10px;
        }

        &--appearances-header {
          margin-top: 30px;
          align-items: center;
          display: flex;
          justify-content: space-between;

          &--actions {
            align-items:center;
            display:flex;
            gap: 20px;
          }
        }

        &--add-appearance {
          margin-top: 30px;

          &--header {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }

          &--body {
            margin-top: 30px;
          }
        }

        &--appearances-list {
          margin-top: 30px;

          &--no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (min-width: 810px) {
  .reviewpage-desktop {
    background-color: $primary-white;
    flex: 1 1 auto;


    &--dotted-list {
      border-left: 1px dashed $primary-grey;
      border-right: 1px dashed $primary-grey;
      margin-bottom: 64px;
      margin-left: auto;
      margin-right: auto;

      &--content {
        height: 100%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-left: 1px dashed $primary-grey;
        border-right: 1px dashed $primary-grey;

        &--header {
          border-bottom: 1px dashed $primary-grey;
          min-height: 80px;
          padding: 16px 20px;
          display:flex;
          align-items:center; /*for vertically center*/
          justify-content: flex-end;

        }

        &--review-details {
          border-bottom: 1px dashed $primary-grey;
          min-height: 80px;
          padding: 16px 20px;
          display:flex;
          align-items:center; /*for vertically center*/
          margin-bottom: 20px;
        }

        &--body {
          padding: 0 20px;

          &--appearances-header {
            margin-top: 30px;
            align-items: center;
            display: flex;
            justify-content: space-between;

            &--actions {
              align-items:center;
              display:flex;
              gap: 20px;
            }
          }

          &--add-appearance {
            margin-top: 30px;

            &--header {
              align-items: center;
              display: flex;
              justify-content: space-between;
            }

            &--body {
              margin-top: 30px;
            }
          }

          &--appearances-list {
            margin-top: 30px;

            &--no-data {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1200px) {
  .reviewpage-desktop {


    &--dotted-list {
      width: 1110px;

      &--review-details {
        padding: 16px 30px;

        margin-bottom: 30px;
      }
    }
  }
}
