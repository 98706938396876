@import "../../../../styles/variables/colors";

.select-field {
  background: #FFFFFB;
  border: 0;
  border-right: 14px solid transparent;
  font-family: 'Space Mono',  serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  left: 4px;
  letter-spacing: 0.2px;
  line-height: 24px;
  outline: 1px solid;
  padding: 5px 16px;
  top: 24px;
  width: 100%;

  &[data-variant='ghost']{
    outline: 1px solid transparent;
  }
}
