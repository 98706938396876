@import "../../../../styles/variables/colors";


.checkbox {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    position: absolute;
    width: 0;
  }

  &--checkmark {
    background: white;
    height: 16px;
    width: 16px;
    border: 1px solid $primary-dark;

    &[data-disabled='true']{
      background: $primary-grey;
      border: 1px solid $primary-grey;
    }

    &[data-bordered='false']{
      border: none;
    }
  }
}

/* When the checkbox is checked, add background */
.checkbox input:checked ~ .checkbox--checkmark {
  background-color: $primary-blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox--checkmark:after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox--checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkbox--checkmark:after {
  margin-left: 5px;
  margin-top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
