@import "../../../../styles/variables/colors";

.request-feed {
  width: 100%;
  margin: 0 128px;
  border-left: 1px dashed $primary-grey;
  border-right: 1px dashed $primary-grey;

  &--border {
    margin: 0 94px;
    padding: 60px 0;

    border-left: 1px dashed $primary-grey;
    border-right: 1px dashed $primary-grey;
    height: 100%;
  }

  &--no-data {
    padding-left: 30px;
  }

  &--title {
    padding: 0 30px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--section {
    border-top: 1px dashed $primary-grey;
    border-bottom: 1px dashed $primary-grey;
    margin-bottom: 60px;
    padding: 20px;
  }
}

.feedback-request {

  &--publicQuestion {
    margin-bottom: 20px;
  }

  &--metrics {
    display: flex;
    justify-content: space-between;
  }
}

