@import "../../../../../styles/variables/colors";

.source-page-header {
  padding: 60px 30px 40px 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &--info {
    
    &--sourcetype {

      text-transform: capitalize;

      &--icon {
        display: flex;
        margin: 10px 0;

        p {
          margin-left: 10px;
        }
      }
    }
  }
}
