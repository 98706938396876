@import "components/index";

.facebook-import {

  &--title {
    color: black;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  select {
    margin-top: 8px;
    width: 250px;
  }

  &--errors {
    color: $red-dark;
    font-size: 14px;
    margin: 8px 0;
    text-align: start;
  }

  &--loader {
    width: 100%;
  }

  &--success {
    color: black;
    margin-right: auto;
    margin-left: auto;
  }

  &--table {
    margin-top: 16px;
    thead {
      font-size: 18px;
    }


    th {
      color: black;
      font-weight: bold;
      text-align: left;
    }
  }
}
