@import "../../../../styles/variables/colors";


.review-header {
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  padding: 16px;

  a {
    color: inherit;
  }

  &--divider {
    margin-top: 30px;
  }


  &--claim-section {
    display: flex;
    margin-top: 30px;
    &--claim {
      margin-left: 10px;
      width: 100%;
    }
  }
}
@media (min-width: 810px) {
  .review-header {
    background-color: $primary-dark;
    flex-direction: row;
    display: flex;
    padding: 30px 0;

    &--divider {
      margin-top: 0;
    }


    a {
      color: inherit;
    }
    a:hover {
      cursor: pointer;
      color: $primary-blue;
    }

    &--verdict-section {
      padding: 20px 28px;
      width: 50%;
    }

    &--claim-section {
      padding: 20px 28px;
      width: 50%;
    }
  }

}
@media (min-width: 1200px) {
  .review-header {
    padding: 30px 166px;
  }
}
