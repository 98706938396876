@import "../../../../../styles/variables/colors";


.header-desktop {
  align-items: center;
  background-color: $primary-white;
  border-bottom: 1px solid $tertiary-grey;
  display: flex;
  justify-content: center;
  padding: 12px 0px;
  position: fixed;
  width: 100%;
  z-index: 2;

  &--content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--logo {
    text-decoration: none;
  }

  &--logout {
    cursor: pointer;

    p {
      display: flex;
      align-items: center;

      svg {
        margin-left: 14px;
      }
    }
  }

  &--center-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--navlink {
    display: flex;


    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $primary-blue;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    p {
      margin-right: 48px;
    }
    & > p:last-child {
      margin-right: 0;
    }


    &--active {
      color: $primary-blue !important;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &--editorlink {
    display: flex;


    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $primary-blue;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    p {
      margin: 0 24px;

      &:last-child {
        margin-right: 0;
      }
    }
    & > p:last-child {
      margin-right: 0;
    }


    &--active {
      color: $primary-blue !important;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}


