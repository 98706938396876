@import "../../../../styles/variables/colors";


.appearance-shares {
  border-top: 1px dashed $primary-grey;
  padding: 0 20px;

  &--header {
    display: flex;
    border-bottom: 1px dashed $primary-grey;

    &--tab {
      display: flex;
      padding: 14px;
      align-items: center;

      &-isActive {
        border-bottom: 4px solid black;

        p {
          color: $primary-dark !important;
        }
      }
    }
  }

  &--body {
    padding: 22px 0;

    &--total {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }

    &--post {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }

    &--link {
      text-align: center;
    }

    table {
      width: 100%;
      border-spacing: 0px;
      border-right: 1px dashed $primary-grey;
      border-left: 1px dashed $primary-grey;

      th {
        border-right: 1px dashed $primary-white;
        background-color: $primary-dark;
        padding: 6px;
      }

      td {
        border-right: 1px dashed $primary-grey;
        padding: 10px;
        border-bottom: 1px dashed $primary-grey;
      }

      td.last,th.last
      {
        border-right: none;
      }
    }

    &--no-data {
      width: 100%;
      border-bottom: 1px dashed $primary-grey;
    }
  }
}

