@import "../../../../styles/variables/colors";

.dashboard-statistic-card {
  display: flex;
  padding: 20px 28px;
  text-decoration: none;

  &:hover {
    background-color: $tertiary-grey;
    cursor: pointer;
  }

  &--add {
    display: flex;
    align-items: center;
  }

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 24px;
    overflow: hidden;
    flex: 1;

    &--no-marging {
      margin-left: 0;
    }

    &--header {
      align-items: center;
      display: flex;
      margin-bottom: 8px;

    }

    &--footer {

      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 8px 0px;
      justify-content: space-between;

      &--divider {
        margin-right: 12px;
      }

      &--item {
        align-items: center;
        display: flex;
        margin-left: 40px;

        &--before-divider   {
          display: flex;
        }
      }
    }
  }
}
