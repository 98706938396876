@import "../../../styles/variables/colors";

.about-us {
  background-color: $primary-white;
  flex: 1 1 auto;
  width: 100%;

  &--open-feedback {
    background-color: $primary-dark;
    color: $primary-white;
    text-align: center;
    padding: 100px 10% 100px 10%;
  }

  &--our-api {
    margin-top: 60px;
  }

  &--desktop {
    &--section {
      margin-top: 100px;
      width: 100%;
      max-width: 1112px;
      margin-right: auto;
      margin-left: auto;

      &--header {
        width: 100%;
        border-bottom: 1px dashed $primary-grey;
        margin-bottom: 60px;
      }

      &-sub {
        margin-top: 60px;
        padding-bottom: 60px;
        border-bottom: 1px dashed $primary-grey;
        margin-bottom: 0;
      }

      &--content {
        display: flex;
        justify-content: space-between;

        &--title {
          margin-bottom: 30px;
        }

        &--divider {
          margin-left: 24px;
          margin-right: 24px;
        }

        &--dataType {
          width: 255px;

          img {
            margin-bottom: 30px;
          }

          b{
            color: $primary-dark;
          }
        }

        &--col {
          flex: 1 1 auto;
          max-width: 49%;

          &-center {
            flex: 1 1 auto;
            max-width: 49%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }


          img {
            height: 250px;
          }

          b{
            color: $primary-dark;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &--mobile {
    &--section {
      margin-top: 80px;
      margin-right: auto;
      margin-left: auto;
      padding-left: 16px;
      padding-right: 16px;

      &--header {
        width: 100%;
        border-bottom: 1px dashed $primary-grey;
        border-top: 1px dashed $primary-grey;
        padding-top: 24px;
        padding-bottom: 24px;
      }

      &--content {
        margin-top: 30px;

        &--title {
          margin-bottom: 30px;
        }

        &--divider {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        &--contact {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        &--swiper {
          &--slide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &--sketch-img {
              width: 80%;
              max-width: 300px;
            }

            &--illustration-img {
              width: 100%;
              margin-top: 30px;
              max-width: 500px;
            }

            &--text {
              margin-top: 30px;
              width: 80%;
              max-width: 300px;
            }
          }
        }
      }

    }
  }
}
