@import "../../styles/variables/colors";

.homepage {
  background-color: $primary-white;
  flex: 1 1 auto;

  &--search {
    background-color: $primary-dark;
    color: $primary-white;
    height: 622px;
    padding-left: 110px;
    padding-top: 140px;

    &--content {
      max-width: 710px;

      &--title {
        margin-bottom: 36px;
      }

      &--subtitle {
        margin-bottom: 36px;
      }
    }
  }

  &--review-list {
    width: 1110px;
    border: 1px dashed $secondary-grey;
    border-bottom: none;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 64px;

    &--header {
      border-bottom: 1px dashed $secondary-grey;
      padding: 0 94px;
      height: 110px;

      &--content {
        height: 100%;
        display: flex;
        align-items: flex-end;
        border-right: 1px dashed $secondary-grey;
        border-left: 1px dashed $secondary-grey;
      }

    }

    &--body {
      padding: 0 94px;

      &--content{
        display: flex;
        flex-direction: column;
        border-right: 1px dashed $secondary-grey;
        border-left: 1px dashed $secondary-grey;

        &--row {
          border-top: 1px dashed $secondary-grey;
          border-bottom: 1px dashed $secondary-grey;
          min-height: 200px;
          margin-top: 60px;
        }

      }
    }
  }
}
