@import "../../../../styles/variables/colors";

.media-card {
  border: 1px dashed $primary-grey;
  display: flex;
  padding: 20px;
  text-decoration: none;

  &:hover {
    background-color: $tertiary-grey;
    cursor: pointer;

    .media-card--body--name {
      text-decoration: underline;
    }

  }

  &--body {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &--header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &--icon {
        height: 14px;
        width: 14px;
        margin-right: 8px;
      }
    }

    &--name {
      margin-bottom: 16px;
    }
  }
}
