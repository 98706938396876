@import "../../../../styles/variables/colors";

.legendStatistic {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &--dotsConnector {
    align-self: flex-end;
    border: none;
    border-top: 2px dotted $primary-grey;
    margin-left: 10px;
    margin-right: 10px;
    flex: 1 1 auto;
    margin-left: 9px;
  }

  &--label, &--numbers {
    line-height: 16px;
    white-space: nowrap;
    margin-left: 8px;
  }

  &--squareGroup {
    display: flex;
  }
}
