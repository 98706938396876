@import "../../../../../styles/variables/colors";

.footer-desktop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 46px 0;
  background-color: $primary-dark;


  &--navlink {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-around;
    max-width: 200px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}
