@import "../../../../styles/variables/colors";


.conclusion-table {
  border-spacing: 0px;
  width: 100%;

  &--head {
    background-color: $primary-dark;
    
    &--cell {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  &--body {
    background-color: $primary-white;
    
    &--row {
      cursor: pointer;
    
      &--cell {
        border: 1px dashed $primary-grey;
        padding: 10px;
  
        &--actions {
          display: flex;
          gap: 10px;
        }
      }
    }

    &--row:hover {
      background-color: $tertiary-blue
    }
    
  }
}
