@import "../../../../../styles/variables/colors";


.review-card-mobile {
  display: flex;
  flex-direction: column;
  padding: 16px;

  a {
    color: inherit;
  }
  &--verdict-section {
    &--divider {
      margin-top: 16px;
    }
  }
  &--claim-section {
    display: flex;
    margin-top: 16px;

    &--claim {
      margin-left: 10px;
      width: 100%;
    }
  }

}
