@import "../../../../styles/variables/colors";


.our-api {
  display: flex;
  align-items: center;
  flex-direction: column;


  &--border {
    border-bottom: 1px dashed #B7B8B8;
    border-color: $primary-grey;
    width: 100%;

    &[data-variant='white']{
      border-color: $primary-grey;
    }

    &[data-variant='dark']{
      border-color: $primary-grey;
    }

    &--bottom {
      margin-top: 60px;
      margin-bottom: 100px;
    }
    &--top {
      margin-bottom: 60px;
    }
  }

  @media (max-width: 767px) {
    padding: 60px 16px;
  }

  &[data-variant='white']{
    border-color: $primary-grey;
  }

  &[data-variant='dark']{
    border-color: $primary-grey;
  }

  &[data-variant='white']{
    background-color: $primary-white;
  }

  &[data-variant='dark']{
    background-color: $primary-dark;
  }

  &--details {
    max-width: 540px;
    margin-bottom: 32px;
    margin-top: 22px;
    text-align: center;
  }
}
