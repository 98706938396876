@import "../../../../styles/variables/colors";

.source-stats {
  display: flex;
  flex-direction: column;
  padding: 30px;

  &--body {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }

  &--tabs {
    width: 100%;
    display: flex;
    border-bottom:  1px dashed $primary-grey;

    &--tab {

      margin-right: 20px;
      padding: 12px 0;

      &-isActive {
        color: $primary-dark !important;

        border-bottom: 4px solid $primary-dark;
        & > p {
          color: $primary-dark !important;
        }
      }
    }


    &--content{
      margin-top: 20px;
      display: flex;
      flex-direction: column;


      &--stats {
        margin-top: 20px;
        display: flex;

        &--details {
          flex: 1 1 auto;
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          &--divider {
            width: 30px !important;
          }

          > div {
            display: flex;
           > div {
              width: calc(50% - 15px);
            }
          }
        }

      }
    }
  }
}


