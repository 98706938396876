@import "../../../../styles/variables/colors";

.conclusionTag {
  display: flex;
  align-items: center;

  &--scoreSquare {
    background-color: $primary-grey;
    height: 16px;
    margin-left: 6px;
    width: 16px;

    &-0 {
      background-color: $score-0;
    }

    &-1 {
      background-color: $score-1;
    }

    &-2 {
      background-color: $score-2;
    }

    &-3 {
      background-color: $score-3;
    }

    &-4 {
      background-color: $score-4;
    }

    &-5 {
      background-color: $score-5;
    }

    &-null {
      background-color: $score-undefined;
    }

    &-other {
      background-color: $score-undefined;
    }

    &-satire {
      background-color: $score-satire;
    }
  }
}
