@import "../../../../styles/variables/colors";

.feedback-card {
  border: 1px dashed $primary-grey;
  padding: 21px;
  margin-bottom: 16px;

  &--title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &--feedback {
    margin: 16px 0;
  }

  &--infos {
    display: flex;
    justify-content: space-between;

    &--tags {
      display: flex;

      &--tag {
        padding: 3px 6px;
        background: #F4F5F6;
        margin-right: 10px;
      }
    }
  }
}
