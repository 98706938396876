@import "../../../../styles/variables/colors";

.confirm-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 15px;

  &--check-animation {
    height: 50px;
  }
}
