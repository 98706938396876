@import "../../../../styles/variables/colors";


.share-card-desktop {
  display: flex;
  padding: 20px 28px;

  &--image {
    margin-right: 15px;
    width: 100px;
  }

  &--body {
    // width = 100% - (width-of-image + image-margin-right)
    width: calc(100% - 115px);

    &--section {
      display: flex;
      flex-direction: column;
      min-height: 100px;

      &--header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &--channel {
          display: flex;
        }
      }
    }

    &--footer {
      align-items: center;
      display: flex;
      flex: 2 1;
      justify-content: space-between;

      > :only-child {
        width: 100%;
      }

      &--group-left {
        align-items: center;
        display: flex;

        > :not(:last-child) {
          margin-right: 15px;
        }
      }

      &--group-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        > :not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}