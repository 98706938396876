@import "../../../../styles/variables/colors";

.scoreSquare {
  background-color: $primary-dark;
  height: 16px;
  margin-right: 2px;
  width: 16px;
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;

  &-0 {
    background-color: $score-0;
  }

  &-1 {
    background-color: $score-1;
  }

  &-2 {
    background-color: $score-2;
  }

  &-3 {
    background-color: $score-3;
  }

  &-4 {
    background-color: $score-4;
  }

  &-5 {
    background-color: $score-5;
  }

  &-satire {
    background-color: $score-satire;
  }

  &-undefined {
    background-color: $score-undefined;
  }
}
