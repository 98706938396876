.source-page-owners {
    display: flex;
    align-items: center;
    padding: 30px 30px 30px 30px;

    &--owners {
        display: flex;
        align-items: center;
        flex: 50%;
        gap: 5px;
    }
    &--media {
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 50%;
    }
}