@import "../../../../styles/variables/colors";

.select {

  &--nodata {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      height: 80px;
      width: auto;
    }
  }

  &--selected {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    &--value {
      align-items: center;
      background-color: $primary-blue;
      display: flex;
      margin-right: 8px;
      padding: 4px 8px;
      margin-bottom: 2px;

      p {
        margin-right: 4px;
      }

      &--remove {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }
    }
  }
}
