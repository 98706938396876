@import "../../../styles/variables/colors";

.resetPasswordPage {
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;

  &--title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
  }

  &--form {
    display: flex;
    gap: 30px;
    width: 100%;
    margin: 0 auto;
  }

}