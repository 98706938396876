@import "../../../../styles/variables/colors";


.full-report {
  height: 226px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0px;
  border-bottom: 1px dashed $primary-grey;
  border-top: 1px dashed $primary-grey;
}
