@import "../../../../styles/variables/colors";


.source-statistics-recalculate {
  margin: 0 30px 30px 30px;

  button {
    margin-left: auto;

  }

  &--stats-recalculated {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  p {
    margin-right: 10px;
  }
}
