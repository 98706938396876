@import "../../../../styles/variables/colors";


.edit-source {
  display: flex;
  align-items: center;
  padding-right: 28px;

  &--divider {
    margin-bottom: 30px;

    &--margin {
      margin-top: 30px;
    }
  }

  &--form {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  &--section {
    margin-bottom: 20px;

    > p {
      margin-bottom: 10px;
    }
  }

  &--actions {
    display: flex;
    justify-content: space-between;
  }
}
