@import "../../../../styles/variables/colors";


.edit-quotations {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 40px;

  &--text {
    flex: none;
    flex-grow: 1;

  }
  &--actions {
    &--action {
      align-items: center;
      display: flex;
      margin-left: 40px;

      &--text {
        margin-right: 5px;
      }

      &--before-divider   {
        margin-right: 40px;
      }
    }
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 8px 0px;
  }
}
