@import "../../../../../styles/variables/colors";


.claim-card-mobile {

  &--content {
    display: flex;
    padding: 16px;

    a {
      color: inherit;

      &:hover {
        color: $primary-blue;
      }
    }

    &--section {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      width: 100%;

      &--header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        &--title {
          background-color: $tertiary-grey;
          padding: 0 18px 0 6px;
        }
      }

      &--body {
        margin-bottom: 16px;

        > a {
          display: block;
        }

        &--link {
          margin-top: 8px;
        }

        &--review-button {
          margin-top: 16px;

          a {
            text-decoration: none;
          }
        }
      }

      &--footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        &--stats-button {
          background: none;
          border: none;

          &:hover {
            cursor: pointer;
          }

          &:disabled {
            cursor: auto;
          }

          p {
            align-items: center;
            display: flex;
          }
        }
      }
    }
  }

  &--links-list {
    padding: 0 20px 20px;

    &--link {
      border: 1px dashed $primary-grey;
      margin-bottom: 20px;
    }
  }
}
