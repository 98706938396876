@import "../../../../../styles/variables/colors";

.footer-mobile {
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 16px;
  height: 132px;
  &--logo-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    &--logo {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 5px;
    }
    &--navlink {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0px 16px;
      &--links {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 24px 0px;
        cursor: pointer;
        a {
          margin-right: 18px;
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      &--title {
        margin: 16px 0px;
      }
    }
  }
}
