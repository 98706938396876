@import "../../../../styles/variables/colors";

.dashboard-source-statistic-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 0px;
  text-decoration: none;

  &--header {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  &--body {
    border-top: solid black 2px;
    display: flex;
    flex-direction: row;
    padding: 8px 0px;
    justify-content: space-between;

    &--divider {
      margin-right: 12px;
    }

    &--item {
      align-items: center;
      display: flex;
      margin-left: 40px;

      &--before-divider   {
        display: flex;
      }
    }

    &--link {
      display: flex;
      align-items: center;
    }
  }
}
