@import "../../../styles/variables/colors";
@import "../../../styles/variables/spacing";

.reviews-with-no-appearances-page {
  display: flex;
  gap: 30px;
  height: calc(100vh - $header-height);
  padding: 40px 40px 0;
  width: 100%;

  &--reviews {
    border-right-color: $primary-grey;
    border-right-style: dashed;
    border-right-width: 1px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: calc(40%);

    &--search {
      padding-right: 10px;
    }

    &--review-list {
      &--review:hover {
        cursor: pointer;
        background-color: $tertiary-grey;
      }
    }
  }
  &--review-profile {
    width: 60%;

    &--divider {
      margin-top: 30px;
    }

    &--treated-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}

#reviews-with-no-appearances-page--reviews--infinite-scroll {
  overflow: scroll;
}
