@import "../../../../../styles/variables/colors";


.claim-card-desktop {
  display: flex;
  padding: 20px;
  border: 1px dashed #B7B8B8;
  margin-bottom: 30px;

  &-light {
    border: none;
    padding: 0;
    margin-bottom: 0px;
  }

  a {
    color: inherit;

    &:hover {
      color: $primary-blue;
    }
  }

  &--section {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    width: 100%;

    &--header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      &--title {
        background-color: $tertiary-grey;
        padding: 0 18px 0 6px;
      }
    }

    &--body {

      > a {
        display: block;
      }

      &--link {
        display: flex;
        margin-top: 8px;

        p > {
          display: flex;
        }
      }
    }

    &--footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      &--readmore {
        margin-left: auto;
      }

      &--stats-button {
        background: none;
        border: none;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: auto;
        }

        p {
          align-items: center;
          display: flex;
        }
      }
    }
  }
}

