@import "../../../../styles/variables/colors";

.quotationslist {
  margin-top: 16px;
  margin-bottom: 16px;

  &--appearance {
    border: 1px dashed $primary-grey;
    margin-top: 16px;
  }
}
