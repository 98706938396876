@import "../../../styles/variables/colors";

.not-found {
  width: 100%;
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;


  &--redirection {
    display: flex;
    align-items: center;
  }
}
