@import "../../../../styles/variables/colors";


.add-quotation {
  display: flex;
  flex-direction: column;

  &--tabs {
    display: flex;
    border-bottom: 1px dashed $primary-grey;
    padding: 0 8px;

    &--tab {
      padding: 20px;

      &-isActive {
        color: $primary-dark !important;

        border-bottom: 4px solid $primary-dark;
        & > p {
          color: $primary-dark !important;
        }
      }
    }
  }
}
