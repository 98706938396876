@import "../../../../styles/variables/colors";


.partners-list-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  margin-bottom: 90px;

  &--text {
    display: flex;
    flex-direction: column;

    &--title {
      margin-bottom: 60px;
      border-bottom: 6px solid $primary-dark;

    }

    &--pagination {
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: auto;


      &--actions {
        margin-top: 10px;
        display: flex;

        button {
          height: 19px;
        }

        & > button:first-child {
          margin-right: 16px;
        }
      }

    }
  }

  &--partners {
    flex: 1 1 auto;
    border: 1px dashed $primary-grey;
    display: flex;
    flex-direction: column;
    height: 300px;

    &--row {
      border-bottom: 1px dashed $primary-grey;
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &--element {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
      }

      img {
        object-fit: contain;
        max-width: 100%;
      }

      &--divider {
        height: 100%;
        width: 1px;
        border-right: 1px dashed $primary-grey;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}


@media (min-width: 810px) {
  .partners-list-desktop {
    padding: 40px;
  }
}

@media (min-width: 1200px) {
  .partners-list-desktop {
    border-left: 1px dashed $primary-grey;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    padding-left: 94px;
    padding-top: 0;
    padding-bottom: 0;
    width: 1110px;
    margin-bottom: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--text {
      border-left: 1px dashed $primary-grey;
      width: 350px;
      margin-right: 30px;
      height: 614px;
      display: flex;
      flex-direction: column;
      padding-left: 30px;

      &--title {
        margin-top: 30%;
        margin-bottom: 60px;
        border-bottom: 6px solid $primary-dark;

      }

      &--pagination {
        margin-top: auto;
        margin-left: auto;

        &--actions {
          margin-top: 10px;
          display: flex;

          button {
            height: 19px;
          }

          & > button:first-child {
            margin-right: 16px;
          }
        }

      }
    }

    &--partners {
      flex: 1 1 auto;
      border: 1px dashed $primary-grey;
      display: flex;
      flex-direction: column;
      height: 600px;

      &--row {
        border-bottom: 1px dashed $primary-grey;
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &--element {
          flex: 1 1 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--divider {
          height: 100%;
          width: 1px;
          border-right: 1px dashed $primary-grey;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

