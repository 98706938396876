.factcheck-scrapper {
  display: flex;
  flex-direction: column;

  &--button {
    display: flex;
    margin-left: auto;
    margin-top: 16px;
  }
}
