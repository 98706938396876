@import "../../../../styles/variables/colors";

.source-card {
  display: flex;
  padding: 20px 28px;
  text-decoration: none;
  display: flex;
  z-index: 0;

  &[data-link="true"] {
    &:hover {
      background-color: $tertiary-grey;
      cursor: pointer;

      .source-card--body--name {
        text-decoration: underline;
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 24px;
    overflow: hidden;
    flex: 1;

    &--header {
      align-items: center;
      display: flex;
      margin-bottom: 8px;
      text-transform: capitalize;

      &--icon {
        height: 14px;
        margin-right: 8px;
        width: 14px;
      }
    }

    &--name {
      margin-bottom: 16px;
    }

    &--metrics {
      display: flex;
    }
  }
}
