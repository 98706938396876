@import "../../../../styles/variables/colors";

.factcheck-csv-import {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--button {
        display: flex;
        margin-left: auto;
        margin-top: 16px;
    }
}