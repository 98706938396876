@import "../../../../styles/variables/colors";

.edit-conclusion {
  &--row {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    &--label {
      margin-bottom: 16px;
    }

    &--badge {
      margin-top: 16px;
    }

    &--checkbox {
      align-items: center;
      display: flex;
      margin-top: 20px;
      gap: 10px;
    }
  }

  &--buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
}
