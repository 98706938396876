@import "../../../../../styles/variables/colors";


.sourcePageAppearances {
  padding: 30px 20px;


  &--title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  
    &--text {
      margin-bottom: 10px;
    }
  }

  &--subtitle {
    margin-bottom: 30px;
  }

  &--header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  &--appearance {
    border: 1px dashed $primary-grey;
    margin-top: 16px;
  }
}

