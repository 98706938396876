@import "../../../styles/variables/colors";

.homepage {
  background-color: $primary-white;
  flex: 1 1 auto;
  overflow: hidden;

  &--our-api-wrapper {
    background-color: $primary-dark;
    text-align: center;
  }
  &--dotted-list {
    border: 1px dashed $primary-grey;
    border-bottom: none;
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
    width: 1110px;

    &--header {
      border-bottom: 1px dashed $primary-grey;
      height: 110px;
      padding: 0 94px;

      &--content {
        align-items: flex-end;
        border-left: 1px dashed $primary-grey;
        border-right: 1px dashed $primary-grey;
        display: flex;
        height: 100%;

          &--title {
            border-bottom: 6px solid $black;
          }

          &--center {
            justify-content: center;
          }
        }

      }

      &--body {
        padding: 0 94px;

        &--content{
          border-left: 1px dashed $primary-grey;
          border-right: 1px dashed $primary-grey;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          &--row {
            border-bottom: 1px dashed $primary-grey;
            border-top: 1px dashed $primary-grey;
            margin-top: 60px;
            width: 100%;
          }

          &--element {
            border: 1px dashed $primary-grey;
            border-left: none;
            margin-top: 60px;
            width: calc(50% - 15px);
            text-decoration: none;

            &:nth-child(2n) {
              border-right: none;
              border-left: 1px dashed $primary-grey;
            }
          }

      }
    }
  }

  &--all-reviews-link  {
    display: flex;
    justify-content: center;
    margin-bottom: 88px;
    width: 100%;
  }

    &--asterisk {
      max-width: 479px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
}
