@import "../../../../styles/variables/colors";


.file-field {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-family: Space Mono, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;

  &--label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &[data-variant="default"] {
    background-color: $tertiary-grey;
    color: $primary-dark;
  }
  &[data-variant="ghost-reverse"] {
    background-color: $primary-dark;
    color: white;
  }

  &--input {
    display: none;
  }
}
