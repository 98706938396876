@import "../../../../styles/variables/colors";

.infiniteScroll {
  &--no-data {
    margin: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}
