@import "../../../../../styles/variables/colors";


.quotation-card-footer {
  align-items: center;
  display: flex;
  flex: 2 1;
  justify-content: space-between;

  > :only-child {
    width: 100%;
  }

  &--group-left {
    align-items: center;
    display: flex;
    gap: 10px;

    &--shares {
      display: flex;
      align-items: center;
    }

    &--divider {
      height: 15px;
    }
  }

  &--group-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 24px;

    > :not(:last-child) {
      margin-right: 15px;
    }


    @keyframes slidein {
      0% {
        transform: translateX(37px);
      }

      100% {
        transform: translateX(0);
      }
    }

    &--animation {
      overflow: hidden;

      &:hover {
        .quotation-card-footer--group-right--conclusion {
          animation: slidein 0.3s forwards;
        }
        .quotation-card-footer--group-right--edit {
          animation: slidein 0.3s forwards;
        }
      }

    }

    &--conclusion {
      transform: translateX(37px);
    }

    &--edit {
      height: 100%;
      display: none;
      align-items: center;
      transform: translateX(100px);

      &--divider {
        height: 100%;
        margin: 0 10px;
      }
    }
  }
}


@media (min-width: 810px) {
  .quotation-card-footer {
    &--group-left {
    }

    &--group-right {

      &--conclusion {
        transform: translateX(37px);
      }

      &--edit {
        display: flex;

      }
    }
  }
}

