@import "../../../../../styles/variables/colors";


.header-mobile {
  align-items: center;
  background-color: $primary-white;
  border-bottom: 1px solid $tertiary-grey;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: fixed;
  width: 100%;
  z-index: 2;

  &--logo {
    text-decoration: none;
  }

  &--menu {
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 50px;


    &--header {
      align-items: center;
      display: flex;
      margin-bottom: 80px;
      position: relative;

      img {
        height: 48px;
        margin-right: 16px;
      }

      &--close {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &--footer {
      margin-top: auto;
    }
  }

}


