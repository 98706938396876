@import "../../../../../styles/variables/colors";


.appearance-card-footer {
  align-items: center;
  display: flex;
  flex: 2 1;
  justify-content: space-between;

  > :only-child {
    width: 100%;
  }

  &--group-left {
    align-items: flex-end;
    display: flex;

    > :not(:last-child) {
      margin-right: 15px;
    }

    &--claims {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &--group-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > :not(:last-child) {
      margin-right: 15px;
    }
  }
}
