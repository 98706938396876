@import "../../../../styles/variables/colors";


.edit-review {
  &--form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--section {
    margin-bottom: 20px;

    > p {
      margin-bottom: 10px;
    }
  }

  &--dropdown {
    width: 100%;
    margin-bottom: 14px;
  }

  &--noscore {
    display: flex;
    align-items: center;

    > p {
      margin-left: 8px;
    }
  }

  &--divider {
    margin-top: 10px;
    margin-bottom: 30px;
  }

}
