@import "../../../../styles/variables/colors";

.divider {
  &--horizontal {
    border-bottom-color: $primary-grey;
    border-bottom-width: 1px;
    height: 1px;
    width: 100%;

    &--solid {
      border-bottom-style: solid;
    }

    &--dashed {
      border-bottom-style: dashed;
    }

    &-thick {
      border-bottom-width: 3px;
    }
  }

  &--vertical {
    border-right-color: $primary-grey;
    border-right-width: 1px;
    width: 1px;

    &--solid {
      border-right-style: solid;
    }

    &--dashed {
      border-right-style: dashed;
    }

    &-thick {
      border-right-width: 3px;
    }
  }


  &--score {
    &-0 {
      border-bottom-color: $score-0;
      border-right-color: $score-0;
    }

    &-1 {
      border-bottom-color: $score-1;
      border-right-color: $score-1;

    }

    &-2 {
      border-bottom-color: $score-2;
      border-right-color: $score-2;
    }

    &-3 {
      border-bottom-color: $score-3;
      border-right-color: $score-3;
    }

    &-4 {
      border-bottom-color: $score-4;
      border-right-color: $score-4;
    }

    &-5 {
      border-bottom-color: $score-5;
      border-right-color: $score-5;
    }
  }
}

