.add-suggested-quotations-search-type {
  margin-top: 16px;
  text-align: right;
}

.add-suggested-quotation--buzzsumo-form {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.add-suggested-quotations-search-backlink {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.select-suggested-appearances-backlinks-appearance-url {
  width: 100%;
}
.add-suggested-quotations-list {
  &--no-data {
    margin: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}

