.delete-feedback-request {
  display: flex;
  justify-content: space-between;

  &--actions {
    display: flex;
    justify-content: flex-end;
  }

  &--deleted {
    display: flex;
    justify-content: space-between;
  }
}
