@import "../../../../styles/variables/colors";

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--big {
    height: 160px;
  }
}

.loader {
  height: 24px;
  margin-right: 10px;
}
