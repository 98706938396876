@import "../../../../styles/variables/colors";

.text-area-field {
  align-items: center;
  background: $primary-white;
  border: 1px solid $primary-dark;
  color: $secondary-grey;
  display: flex;
  font-family: Space Mono, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 0 16px;
  width: 100%;

  &[data-rounded='true']{
    border-radius: 18px;
  }

  &:focus {
    border: 2px solid $primary-blue;
    outline: none;
  }

  &:disabled {
    background: $tertiary-grey;
    border: none;
    color: $primary-grey;
    cursor: not-allowed;
  }
}
