.verdict-tags-page {

  margin-left: auto;
  margin-right: auto;
  width: 1110px;

  &--search {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    padding: 0px;
    gap: 30px;
    margin-bottom: 30px;

    &--btn-back {
      display: flex;
      align-items: center;
    }
    &--bar {
      flex: 1 1 auto;
    }

    &--rating {
      width: 240px;
    }
  }

  &--table {
    margin-top: 16px;
    &--buttons {
      margin-top: 10px;
      display: flex;
      justify-content: right;
      gap: 12px;
    }
  }
}
