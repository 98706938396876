@import "../../../../styles/variables/colors";

.logo {
  img {
    margin-right: 10px;
  }
  display: flex;
  font-family: Darker Grotesque;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 47px;

  &-dark {
    color: $primary-dark;

    div {
      color: $primary-dark;
    }
  }


  &-white {
    color: $primary-white;

    div {
      color: $primary-white;
    }

    img {
      filter: invert(1);
    }
  }
}
