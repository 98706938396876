.factChecker-selector {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--title {
    color: black;
    display: flex;
  }

  &--warning {
    color: $red-dark;
    margin-top: 8px;
  }
}
