@import "../../../../styles/variables/colors";


.login-form {
  display: flex;
  flex-direction: column;
  padding-top: 30%;

  &--title {
    width: 100%;
    margin-bottom: 36px;
  }

  input  {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--submit-button {
    margin-top: 240px;
    display: flex;
    justify-content: right;

    &--icon {
      margin-left: 10px;
    }
  }
}
