@import "../../../../styles/variables/colors";


.create-review {
  &--form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--section {
    margin-bottom: 20px;

    > p {
      margin-bottom: 10px;
    }

    .search-topic {
      &--badge {
        display: flex;
        flex-wrap: wrap;

        .badge {
          margin-right: 5px;
        }
      }
    }

    &-title {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &--dropdown {
    width: 100%;
    margin-bottom: 14px;
  }

  &--noscore {
    display: flex;
    align-items: center;

    > p {
      margin-left: 8px;
    }
  }

  &--divider {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &--submit {
    display: flex;
    justify-content: flex-end;
  }

}
