@import "../../../../../styles/variables/colors";


.add-new-quotation {
  display: flex;
  flex-direction: column;


  &--info {
    display: flex;
    svg {
      margin-right: 20px;
    }
  }

  &--row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 34px;

    &--label {
      margin-bottom: 16px;
    }

    &--checkbox {
      align-items: center;
      display: flex;
      margin-top: 20px;
      gap: 10px;
    }

    &--file-infos {
      background-color: $tertiary-grey;

      &--name {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }
  }

  &--add-media-button {
    display: flex;
    margin-top: 16px;
    margin-left: auto;
    margin-bottom: 30px;
  }

  &--button {
    display: flex;
    margin-left: auto;
    margin-top: 16px;
    width: 177px;
  }
}
