@import 'modules/HomePage';
@import 'modules/FacebookImport/facebookImport';
@import "./variables/colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; // REM
  min-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

body {
  color: $primary-dark;
  font-family: 'Space Mono',  serif;
  font-size: 16px;
  font-weight: 400;
  min-height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
}
