@import "../../../../styles/variables/colors";


.edit-appearance-document {
  &--form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--section {
    margin-bottom: 20px;

    > p {
      margin-bottom: 10px;
    }

    &--file-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &--divider {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
