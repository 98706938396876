@import "../../../../styles/variables/colors";


.review-details {
  &--header{
    display: flex;
    justify-content: space-between;

    button {
      margin-left: auto;
    }

    &--edit {
      p {
        margin-right: 10px;
      }
    }
  }

  &--title {
    margin-bottom: 10px;
  }

  &--divider {
    margin: 0 20px;
  }

  &--review {
    margin-bottom: 14px;
  }

  &--row {
    display: flex;
    margin-bottom: 14px;

    p {
      word-break: break-word;
    }
    > p:first-child {
      margin-right: 8px;
      white-space: nowrap;
    }

  }

  &--section {
    margin-top: 30px;

    &--review-and-claim {
      display: flex;

      &--review {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-width: 50%;
      }

      &--claim {
        display: flex;
        flex-direction: column;
      }
    }
  }

}

@media (min-width: 810px) {}
@media (min-width: 1200px) {
  .review-details {
    &--header{
      display: flex;
      justify-content: space-between;

      button {
        margin-left: auto;
      }

      &--edit {
        p {
          margin-right: 10px;
        }
      }
    }

    &--title {
      margin-bottom: 10px;
    }

    &--divider {
      margin: 0 20px;
    }

    &--review {
      margin-bottom: 14px;
    }

    &--row {
      display: flex;
      margin-bottom: 14px;

      p {
        word-break: break-word;
      }
      > p:first-child {
        margin-right: 8px;
        white-space: nowrap;
      }

    }

    &--section {
      margin-top: 30px;

      &--review-and-claim {
        display: flex;

        &--review {
          display: flex;
          flex-direction: column;
          width: 50%;
          min-width: 50%;
        }

        &--claim {
          display: flex;
          flex-direction: column;
        }
      }
    }

  }
}
