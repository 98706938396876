@import "../../../styles/variables/colors";

.factchecker-add-review {
  display: flex;
  width: 100%;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  border-right: 1px dashed $primary-grey;
  border-left: 1px dashed $primary-grey;
  &--column {
    flex: 1;
    max-width: 50%;
  }
  &--divider {
    margin: 0 30px;
  }
}
