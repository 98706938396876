@import "../../../../../styles/variables/colors";


.appearance-card-mobile {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &--content {
    display: flex;

    &--image {
      flex: 0 0 48px;
      margin-right: 16px;
    }

    &--information {
      display: flex;
      flex-direction: column;

      &--header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
    }
  }
  &--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
}
