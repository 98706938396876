@import "../../../../styles/variables/colors";

.badge {
  display: flex;
  margin: 8px 0;

  &--text {
    background-color: $primary-blue;
    padding: 4px 8px;
  }
  &--icon {
    background-color: $primary-blue;
    padding-top: 8px;
    padding-right: 4px;
  }
}
