// new pallet
$primary-dark: #18201E;
$primary-red: #B83020;
$primary-white: #FFFFFB;

$primary-blue: #3A5DAE; 
$secondary-blue: #5778C7;
$tertiary-blue: #EAEAFF;

$primary-grey: #B7B8B8;
$secondary-grey: #5A7770;
$tertiary-grey: #F4F5F6;

$score-0: $primary-red;
$score-1: #E85241;
$score-2: #FFA951;
$score-3: #FCE28C;
$score-4: #66B564;
$score-5: #1A7A4C;
$score-satire: $secondary-blue;
$score-undefined: $tertiary-grey;

  // Old colors
$black:             hsl(0, 0%, 4%) !default;
$black-bis:         hsl(0, 0%, 7%) !default;
$black-ter:         hsl(0, 0%, 14%) !default;
$blue-lighterer:    #FAFCFF;
$blue-lighter:      #EDF4FF;
$blue-light:        #96BCFF;
$blue:              #1162FB;
$blue-dark:         #1565C0;
$blue-darker:       #003084;
$bluegreen:         #007B7B;
$bluegreen-light:   #C9F0F0;
$brown:             #827717;
$cyan:              hsl(204, 86%, 53%) !default;
$green:             hsl(141, 71%, 48%) !default;
$grey-very-lighter: #F5F6F9;
$grey-lighter:      #F2F3F6;
$grey-light:        #E4E6F4;
$grey:              #9DA2C4;
$grey-dark:         #808080;
$grey-darker:       #1F2338 !default;
$orange:            #FFA63A;
$orange-light:      #FFEAAC;
$orange-dark:       hsl(14, 100%, 45%) !default;
$purple:            hsl(271, 100%, 71%) !default;
$red:               #FF6D51;
$red-dark:          #6d071a;
$red-light:         #FFC7BD;
$turquoise:         #00B0E2;
$turquoise-dark:    #026480;
$violet:            #BD10E0;
$white-ter:         hsl(0, 0%, 96%) !default;
$white-bis:         hsl(0, 0%, 98%) !default;
$white:             hsl(0, 0%, 100%) !default;
$yellow:            hsl(48, 100%, 67%) !default;

$primary: $blue;

$text: $black;
$input-color: $text;
$link: $text;
$subtitle-color: inherit;
$success: $green;
$title-color: inherit;
$text-strong: inherit;


$blue-primary:      #005cff;
$blue-secondary:    #1b85ff;
$blue-tertiary:     #3E83FF;

$bg-score-null:     #E4E6F4;
$color-score-null:  white;

$bg-score-0:        #f894a7;
$color-score-0:     #6d071a;

$bg-score-1:        #ffeaea;
$color-score-1:     #ff6d51;

$bg-score-2:        #fff5ea;
$color-score-2:     #ffa951;

$bg-score-3:        #fff2bd;
$color-score-3:     #f0c000;

$bg-score-4:        #eaffea;
$color-score-4:     #00cd6a;

$bg-score-5:        #C9F0F0;
$color-score-5:     #007B7B;
