@import "../../../../../../styles/variables/colors";


.quotation-file {

  &--container {
    background-color: $primary-white;
  }

  &--file:nth-of-type(1)
  {
    border-top: 1px dashed $primary-grey;
  }
  &--file:last-of-type {
    margin-bottom: 20px;
  }
  &--file {
    border-bottom: 1px dashed $primary-grey;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--info {

    }

    &--name {
      margin-bottom: 10px;
    }
  }

  &--file-infos {
    padding: 20px;
    background-color: $tertiary-grey;

    &--name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &--action {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
