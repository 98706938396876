@import "../../../../styles/variables/colors";

.reviewsList {
  padding: 40px 20px;

  &--dotted-list {
    &--header {
      border-bottom: 1px dashed $primary-grey;
      margin-bottom: 30px;

      &--content {
        align-items: flex-end;
        display: flex;
        height: 100%;

        &--title {
          border-bottom: 6px solid $black;
        }
      }
    }

    &--body {

      &--content {

        &--row {
          border: 1px dashed $primary-grey;
          margin-bottom: 30px;
        }
      }
    }
  }

  &--all-reviews-link  {
    display: flex;
    justify-content: center;
    padding-bottom: 96px;
    width: 100%;
    border-bottom: 1px dashed $primary-grey;
  }
}


@media (min-width: 810px) {
  .reviewsList {
    padding: 40px 40px;
  }
}



@media (min-width: 1200px) {
  .reviewsList {
    &--dotted-list {
      border: 1px dashed $primary-grey;
      border-bottom: none;
      margin-bottom: 64px;
      margin-left: auto;
      margin-right: auto;
      width: 1110px;

      &--header {
        border-bottom: 1px dashed $primary-grey;
        height: 110px;
        padding: 0 94px;

        &--content {
          align-items: flex-end;
          border-left: 1px dashed $primary-grey;
          border-right: 1px dashed $primary-grey;
          display: flex;
          height: 100%;

          &--title {
            border-bottom: 6px solid $black;
          }

          &--center {
            justify-content: center;
          }
        }
      }

      &--body {
        padding: 0 94px;

        &--content {
          border-left: 1px dashed $primary-grey;
          border-right: 1px dashed $primary-grey;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          &--row {
            border-bottom: 1px dashed $primary-grey;
            border-top: 1px dashed $primary-grey;
            margin-top: 60px;
            width: 100%;
          }

          &--element {
            border: 1px dashed $primary-grey;
            border-left: none;
            margin-top: 60px;
            width: calc(50% - 15px);
            text-decoration: none;

            &:nth-child(2n) {
              border-right: none;
              border-left: 1px dashed $primary-grey;
            }
          }
        }
      }
    }
    &--all-reviews-link  {
      border-bottom: none;
    }
  }
}
