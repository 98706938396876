@import "../../../../../styles/variables/colors";


.quotation-card-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &--channel {
    display: flex;
    gap: 12.5px;
  }

  &--actions {
    align-items: center;
    display: flex;
    gap: 10px;

    &--cancel {
      svg {
        margin-left: 10px;
      }
    }

    &--icon {
      margin-top: 0px;

      > .component-button {
        padding-left: 10px;
      }

      &--dropdown {
        align-items: flex-start;
        background-color: $primary-white;
        border: 1px solid $primary-dark;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        min-width: 189.5px;
        padding: 8px 0px;
        position: absolute;
        z-index: 1;

        &--item {
          display: block;
          gap: 10px;
          padding: 5px 10px;
          a {
            color: black;
            text-decoration: none;
          }
        }

      }
    }
  }
}
