@import "../../../../../styles/variables/colors";


.review-card-desktop {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  position:relative;

  a {
    position:relative;
    z-index: 1;
    color: inherit;
  }
  a:hover {
    cursor: pointer;
    color: $primary-blue;
  }

  &--link {
    position:absolute !important;
    left:0; top:0; bottom:0; right:0;
  }

  &:hover {
    background-color: $tertiary-grey;
    cursor: pointer;

    .review-card-desktop--section--body--review {
      text-decoration: underline;
    }
  }



  &--verdict-section {
    flex: 0 0 calc(50% - 1px);
    padding-right: 24px;
  }

  &--claim-section {
    flex: 0 0 calc(50% - 1px);
    padding-left: 24px;

  }

}
