@import "../../../../styles/variables/colors";


.review {
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
  }
  a:hover {
    cursor: pointer;
    color: $primary-blue;
  }

  &--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    &--title {
      background-color: $tertiary-grey;
      padding: 0 18px 0 6px;
    }

    &--claimBy {
      text-align: end;
    }
  }

  &--body {
    margin-bottom: 32px;

    &--link {
      margin-top: 8px;
    }
  }

  &--footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &[data-variant="ghost"] {
    &:hover {
      background-color: $tertiary-grey;
      cursor: pointer;

      .verdict--body--review {
        text-decoration: underline;
      }
    }
  }
}
