@import "../../../../styles/variables/colors";

.text-field {
  align-items: center;
  background: $primary-white;
  border: 1px solid $primary-dark;
  color: $secondary-grey;
  display: flex;
  font-family: Space Mono, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 34px;
  line-height: 24px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;


  &[data-rounded='true']{
    border-radius: 38px;
  }

  &:focus {
    border: 1px solid $primary-blue;
    outline: none;
  }

  &:disabled {
    background: $tertiary-grey;
    border-color: $tertiary-grey;
    color: $primary-grey;
    cursor: not-allowed;
  }
}
