@import "../../../../styles/variables/colors";


.contact-form {
  display: flex;
  flex-direction: column;
  padding-top: 30%;

  &--title {
    width: 100%;
    margin-bottom: 36px;
    text-align: initial;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    input {
      width: calc(50% - 8px);
    }
  }

  input, textarea, select {
    margin-bottom: 16px;
  }

  &--buttons {
    display: flex;
    justify-content: right;
    margin-top: 30px;

    svg {
      margin-left: 10px;
    }
  }
}
