@import "../../../../../styles/variables/colors";

.csv-info {
  margin-top: 16px; 
  &--loader {
    text-align: center;
  }
  &--results {
    &--title {
      margin-bottom: 30px;
    }
    &--body {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  
}