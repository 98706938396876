@import "../../../../styles/variables/colors";

.create-feedback-request {
  margin: 0 128px;
  border-left: 1px dashed #B7B8B8;
  border-right: 1px dashed #B7B8B8;

  &--border {
    margin: 0 94px;
    border-left: 1px dashed #B7B8B8;
    border-right: 1px dashed #B7B8B8;
    padding: 60px 21px;
    height: 100%;
  }

  &--details {
    margin-bottom: 36px;
  }

  &--label {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  input {
    margin-bottom: 16px;
  }

  button {
    margin-top: 16px;
    margin-left: auto;
  }
}
